<template>
  <v-card>
    <v-card-title>
      <h4 class="dxa_modal_title h4">
        {{ $tc("manage_financial_field", 1) }}
      </h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="add_analysis()" ref="form" class="mt-5">
        <div class="mx-2" v-if="!loading">
          <v-select
            :items="IndexOptions"
            :label="$t('index')"
            v-model="analysis.Index"
            outlined
          ></v-select>
          <v-row>
            <v-col cols="2">
              <v-select
                :items="availableLangs"
                :label="$t('language')"
                v-model="titleSelectedLang"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model="analysis.Title[titleSelectedLang]"
                :label="$t('title')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-select
                :items="availableLangs"
                :label="$t('language')"
                v-model="textSelectedLang"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model="analysis.Text[textSelectedLang]"
                :label="$t('text')"
              />
            </v-col>
          </v-row>
        </div>
        <v-row justify="center" v-else>
          <v-progress-circular
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-card-actions>
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close')"
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-alert class="ma-2" v-if="error" type="error">{{ $t(error) }}</v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "ManageAnalysisDialog",
  props: {
    Analysis: Object,
    BriefingId: Number,
    CompanyId: Number,
    IndexOptions: Array,
    AnalysisType: Number,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    edit: false,
    titleSelectedLang: null,
    textSelectedLang: null,
    error: null,
    availableLangs: [],
    analysis: null,
  }),

  components: {},

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },

  async created() {
    this.availableLangs = Object.keys(this.$i18n.messages);
    this.textSelectedLang = this.$i18n.locale;
    this.titleSelectedLang = this.$i18n.locale;
    if (this.Analysis) {
      this.edit = true;
      this.analysis = {
        Id: this.Analysis.Id,
        CompanyId: this.Analysis.CompanyId,
        BriefingId: this.Analysis.BriefingId,
        Title: {},
        Index: this.Analysis.Index,
        Text: {},
        Type: this.AnalysisType,
      };
      if (this.gs.isJson(this.Analysis.Title)) {
        this.analysis.Title = JSON.parse(this.Analysis.Title);
      } else {
        this.analysis.Title = {
          pt: this.Analysis.Title,
        };
      }

      if (this.gs.isJson(this.Analysis.Text)) {
        this.analysis.Text = JSON.parse(this.Analysis.Text);
      } else {
        this.analysis.Text = {
          pt: this.Analysis.Text,
        };
      }
    } else {
      this.edit = false;
      this.analysis = {
        CompanyId: this.CompanyId,
        Title: {},
        Index: this.IndexOptions[this.IndexOptions.length - 1],
        Text: {},
        Type: this.AnalysisType,
        BriefingId: this.BriefingId,
      };
    }
    for (var i = 0; i < this.availableLangs.length; i++) {
      if (!this.analysis.Text[this.availableLangs[i]]) {
        this.analysis.Text[this.availableLangs[i]] = "";
      }
      if (!this.analysis.Title[this.availableLangs[i]]) {
        this.analysis.Title[this.availableLangs[i]] = "";
      }
    }
  },
  methods: {
    checkForm: function () {
      this.error = null;
      var result = this.$refs.form.validate();
      if (result) {
        this.add_analysis();
      } else {
        this.loading = false;
      }
    },
    add_analysis: async function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.analysis.Title = JSON.stringify(this.analysis.Title);
      this.analysis.Text = JSON.stringify(this.analysis.Text);
      if (this.edit) {
        await this.apiService
          .putRequest("companyanalysis/edit", this.analysis)
          .then((result) => {
            var objs = JSON.parse(result.message);
            this.$emit("reset", objs);
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      } else {
        await this.apiService
          .postRequest(`companyanalysis/add`, this.analysis)
          .then((result) => {
            var objs = JSON.parse(result.message);
            this.$emit("reset", objs);
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      }
      this.loading = false;
    },
  },
};
</script>
